body {
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #f5f9fa;
  user-select: none;
}

.border-had {
  border-color: rgba(0, 0, 0, 0.125);
}

.position-center {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  z-index: 2;
}

.list-group-borderless .list-group-item {
  border: 0;
}

.list-group-sm .list-group-item {
  padding: 0.3rem;
}

.flex-basis-0 {
  flex-basis: 0;
}

.buttons-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0.3rem;
}

.buttons-grid-container .btn-block + .btn-block {
  margin-top: 0;
}

.buttons-grid-container .buttons-grid-item-fill {
  grid-column-start: 1;
  grid-column-end: 4;
}

.buttons-grid-container-market {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-gap: 0.3rem;
}

.buttons-grid-container-market .btn-block {
  white-space: nowrap;
}
.buttons-grid-container-market .btn-block + .btn-block {
  margin-top: 0;
}

.daypicker-grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
}

.table-fixed {
  table-layout: fixed;
  word-wrap: break-word;
}

.switch {
  font-size: 1rem;
  position: relative;
}
.switch input {
  position: absolute;
  height: 1px;
  width: 1px;
  background: none;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0;
}
.switch input + label {
  position: relative;
  min-width: calc(calc(2.375rem * 0.8) * 2);
  border-radius: 0;
  height: calc(2.375rem * 0.8);
  line-height: calc(2.375rem * 0.8);
  display: inline-block;
  cursor: pointer;
  outline: none;
  user-select: none;
  vertical-align: middle;
  text-indent: calc(calc(calc(2.375rem * 0.8) * 2) + 0.5rem);
}
.switch input + label::before,
.switch input + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(calc(2.375rem * 0.8) * 2);
  bottom: 0;
  display: block;
}
.switch input + label::before {
  right: 0;
  background-color: #dee2e6;
  border-radius: 0;
  transition: 0.2s all;
}
.switch input + label::after {
  top: 2px;
  left: 2px;
  width: calc(calc(2.375rem * 0.8) - calc(2px * 2));
  height: calc(calc(2.375rem * 0.8) - calc(2px * 2));
  border-radius: 0;
  background-color: white;
  transition: 0.2s all;
}
.switch input:checked + label::before {
  background-color: #08d;
}
.switch input:checked + label::after {
  margin-left: calc(2.375rem * 0.8);
}
.switch input:focus + label::before {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 221, 0.25);
}
.switch input:disabled + label {
  color: #868e96;
  cursor: not-allowed;
}
.switch input:disabled + label::before {
  background-color: #e9ecef;
}
.switch.switch-sm {
  font-size: 0.875rem;
}
.switch.switch-sm input + label {
  min-width: calc(calc(1.9375rem * 0.8) * 2);
  height: calc(1.9375rem * 0.8);
  line-height: calc(1.9375rem * 0.8);
  text-indent: calc(calc(calc(1.9375rem * 0.8) * 2) + 0.5rem);
}
.switch.switch-sm input + label::before {
  width: calc(calc(1.9375rem * 0.8) * 2);
}
.switch.switch-sm input + label::after {
  width: calc(calc(1.9375rem * 0.8) - calc(2px * 2));
  height: calc(calc(1.9375rem * 0.8) - calc(2px * 2));
}
.switch.switch-sm input:checked + label::after {
  margin-left: calc(1.9375rem * 0.8);
}
.switch.switch-lg {
  font-size: 1.25rem;
}
.switch.switch-lg input + label {
  min-width: calc(calc(3rem * 0.8) * 2);
  height: calc(3rem * 0.8);
  line-height: calc(3rem * 0.8);
  text-indent: calc(calc(calc(3rem * 0.8) * 2) + 0.5rem);
}
.switch.switch-lg input + label::before {
  width: calc(calc(3rem * 0.8) * 2);
}
.switch.switch-lg input + label::after {
  width: calc(calc(3rem * 0.8) - calc(2px * 2));
  height: calc(calc(3rem * 0.8) - calc(2px * 2));
}
.switch.switch-lg input:checked + label::after {
  margin-left: calc(3rem * 0.8);
}
.switch + .switch {
  margin-left: 1rem;
}

::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}
